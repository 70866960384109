html,
body {
  overflow: hidden;
  background-color: #f9f3eb !important;
  margin: 0px !important;
  padding: 0px !important;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-basis: 100%;
  height: 100%;
  /* background-color: red; */
  background-color: #f9f3eb !important;
}
#snapmentor_backdrop {
  position: relative;
  z-index: 9999;
  /* background-color: red; */
}

.ant-table-filter-dropdown-link {
  color: #a1c6da !important;
}

.ant-notification {
  margin-right: 10px;
}
.ant-notification-notice {
  max-width: calc(100vw - 10px * 2);
}

.ant-dropdown-menu {
  border-radius: 5px !important;
}

.anticon.ant-notification-notice-icon-success {
  color: #139982;
}

.ant-tooltip-inner {
  border-radius: 20px !important;
}

html {
  font-family: 'Nunito';
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito';
}
#root {
  font-family: 'Nunito';
}

::selection {
  background: #f99746;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/nunito-v13-latin-200.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/nunito-v13-latin-200italic.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-v13-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/nunito-v13-latin-300italic.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-v13-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/nunito-v13-latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-v13-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/nunito-v13-latin-700italic.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/nunito-v13-latin-900.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/nunito-v13-latin-900italic.woff') format('woff');
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Excalidraw custom styles */
.excalidraw .excalidraw-tooltip-wrapper {
  display: none !important;
}
.excalidraw
  .App-bottom-bar
  .App-toolbar-content
  > .ToolIcon_type_button:first-child {
  display: none !important;
}
.excalidraw .help-icon {
  display: none !important;
}
.excalidraw .ToolIcon__library {
  display: none !important;
}
.excalidraw .ToolIcon__lock {
  display: none !important;
}
.App-menu_top > .Stack_vertical > .zen-mode-transition:first-child {
  display: none !important;
}
.excalidraw .Island.App-menu__left {
  min-height: 200px;
  width: 202px;
  display: flex;
}
.excalidraw .Island .panelColumn fieldset:last-child,
.excalidraw .Island .panelColumn fieldset:nth-last-child(2) {
  visibility: hidden;
}
.excalidraw .Island .color-picker-control-container .color-input-container {
  display: none;
}
.excalidraw .Island.App-menu__left button[title='Send to back — Cmd+Option+['],
.excalidraw .Island.App-menu__left button[title='Send backward — Cmd+['],
.excalidraw
  .Island.App-menu__left
  button[title='Bring to front — Cmd+Option+]'],
.excalidraw .Island.App-menu__left button[title='Bring forward — Cmd+]'] {
  display: none;
  margin-bottom: -100px;
}
.excalidraw
  .Island.App-menu__left
  .buttonList
  button[title='Duplicate — Cmd+D'],
.excalidraw .Island.App-menu__left .buttonList button[title='Delete'] {
  margin-bottom: 20px;
}
.excalidraw .panelColumn fieldset:nth-last-child(2) {
  margin-top: -40px;
}

/*
  Hide the text that separate excalidraw defualt colors and colors used on the canvas
  Just hide it so that the user thinks they are part of the same palette
*/
.excalidraw .color-picker-content--canvas-title {
  display: none;
}

/* hide the excalidraw eraser button */
.excalidraw .eraser-buttons {
  display: none !important;
}
.excalidraw .eraser {
  display: none !important;
}

/* move excalidraw canvas detected colors to look like they are the same as the default colors -- START */
.excalidraw .color-picker-content--default {
  padding: 0.5rem 0.5rem 0px 0.5rem !important;
}
.excalidraw .color-picker-content--canvas {
  padding: 0rem 0.25rem 0.25rem 0.25rem !important;
}
/* move excalidraw canvas detected colors to look like they are the same as the default colors -- DONE */
